<template>
  <div class="main">
    <van-grid direction="horizontal" :column-num="2" :gutter="30">
      <van-grid-item
        icon="Icontype1"
        text="废气固定源"
        to="/foctordetail/MON_GAS_OUTLET/废气固定源"
      />
      <van-grid-item
        icon="Icontype2"
        text="厂界大气站"
        to="/foctordetail/MON_ATMOSPHERE_STATION/厂界大气站"
      />
      <van-grid-item
        icon="Icontype3"
        text="有机废水"
        to="/foctordetail/VIEW_MON_WATER_OUTLET_MON_TYPE_1/有机废水"
      />
      <van-grid-item
        icon="Icontype4"
        text="无机废水"
        to="/foctordetail/VIEW_MON_WATER_OUTLET_MON_TYPE_2/无机废水"
      />
      <van-grid-item
        icon="Icontype5"
        text="雨水"
        to="/foctordetail/VIEW_MON_WATER_OUTLET_MON_TYPE_3/雨水"
      />
      <van-grid-item
        icon="Icontype6"
        text="土壤"
        to="/foctordetail/MON_SOIL_POINT/土壤"
      />
      <van-grid-item
        icon="Icontype7"
        text="地下水"
        to="/foctordetail/MON_GROUNDWATER_POINT/地下水"
      />
      <van-grid-item
        icon="Icontype9"
        text="噪音"
        to="/foctordetail/MON_NOISE_POINT/噪音"
      />
    </van-grid>
  </div>
</template>
